import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('command/getAll', { alarm_compatible: true })
  const commandOptions = computed(() => store.getters['command/getSelectedItems'].filter(c => c.cmd === 'custom'));

  const tableColumns = [
    { label: 'commands.logs.table.id_operation', key: 'id_operation', sortable: true },
    { label: 'commands.logs.table.description', key: 'description', sortable: false },
    { label: 'commands.logs.table.command', key: 'command', sortable: false },
    { label: 'commands.logs.table.level', key: 'levelText', sortable: false },
    { label: 'commands.logs.table.command_source', key: 'command', sortable: false },
    { label: 'commands.logs.table.data', key: 'data', sortable: false },
    { label: 'commands.logs.table.date', key: 'date', sortable: true, formatter: 'formatDateAssigned' },
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const selectedCommands = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('command/getLogs', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      commands: selectedCommands.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, selectedCommands], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
    commandOptions,
    selectedCommands,
  }
}
